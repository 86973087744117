<template>
  <v-dialog v-model="open" persistent max-width="600">
    <v-card>
      <v-card-title class="mb-3">
        {{ dialog.type === "create" ? "新增分類" : "編輯分類" }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="payload.name"
              label="名稱"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="payload.ichibanIds"
              :items="ichibanOptions"
              label="一番賞"
              outlined
              hide-details
              clearable
              multiple
              chips
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="open = false" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialog.type === 'create'"
          text
          color="primary"
          @click="create"
          class="bt-text-large"
        >
          建立
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="update"
          class="bt-text-large"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from "@/mixins/util";

export default {
  name: "DialogCategory",
  props: ["value", "dialog", "category", "ichibans"],
  mixins: [util],

  data: () => ({
    amount: null,
    payload: {
      name: "",
      ichibanIds: [],
    },
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ichibanOptions() {
      return this.ichibans
        .filter((ichiban) => ichiban.isOnline)
        .sort(this.sortDateDesc("createdAt"))
        .map((ichiban) => {
          return {
            text: `${ichiban.key} - ${ichiban.name}`,
            value: ichiban._id,
          };
        });
    },
  },
  created() {
    if (this.category) {
      this.payload.name = this.category.name;
      this.payload.ichibanIds = this.category.ichibans.map(
        (ichiban) => ichiban._id
      );
    }
  },
  methods: {
    async create() {
      if (!this.payload.name) {
        this.$toast.error("請填寫分類名稱");
        return;
      }

      try {
        this.$vloading.show();
        await this.axios.post(`/ichibanCategory`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "建立失敗");
      }
      this.$vloading.hide();
    },
    async update() {
      this.$vloading.show();

      try {
        await this.axios.put(`/ichibanCategory/${this.category._id}`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response?.data?.message || "編輯失敗");
      }
      this.$vloading.hide();
    },
  },
};
</script>
