<template>
  <v-container fluid>
    <h3 class="page-title">一番賞分類管理</h3>
    <v-row>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="primary" dense hide-details @click="addCategory">
          新增
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="3">
        <v-text-field
          v-model="keyword"
          label="關鍵字"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [30, 100, -1],
          }"
        >
          <template v-slot:item.isAvailable="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isAvailable"
              @change="update(item, 'isAvailable')"
            ></v-checkbox>
          </template>
          <template v-slot:item.ichibans="{ item }">
            <div v-for="ichiban of item.ichibans">
              #{{ ichiban.key }} - {{ ichiban.name }}
            </div>
          </template>
          <template v-slot:item.update="{ item }">
            <v-btn text color="primary" @click="openDialog(item, 'update')"
              >編輯</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogCategory
      v-if="dialog.open"
      v-model="dialog.open"
      @load="load"
      :ichibans="ichibans"
      :category="selectedItem"
      :dialog="dialog"
    />
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import util from "@/mixins/util.vue";
import DialogCategory from "@/components/dashboard/ichiban/DialogCategory.vue";

export default {
  name: "IchibanCategory",
  mixins: [util],
  components: { DialogCategory },
  data: () => ({
    data: [],
    ichibans: [],
    branch: null,
    isOnline: null,
    isAvailable: true,
    dialog: {
      open: false,
      type: "update",
    },
    selectedItem: null,
    keyword: "",
    headers: [
      {
        text: "名稱",
        value: "name",
        align: "left",
        sortable: false,
        width: 100,
      },
      {
        text: "上架",
        value: "isAvailable",
        align: "center",
        sortable: false,
        width: 80,
      },
      {
        text: "一番賞",
        value: "ichibans",
        align: "center",
        sortable: false,
      },
      { text: "", value: "update", align: "center", sortable: false },
    ],
  }),
  computed: {
    filterData() {
      let data = this.data;

      // if (this.isAvailable !== null) {
      //   if (this.isAvailable) {
      //     data = data.filter((item) => item.isAvailable);
      //   } else {
      //     data = data.filter((item) => !item.isAvailable);
      //   }
      // }
      if (this.keyword) {
        data = data.filter(
          (item) =>
            item.name.includes(this.keyword) || item.key.includes(this.keyword)
        );
      }
      return data;
    },
  },
  created() {
    // this.$nextTick(() => {
    //   this.ready = true;
    this.getIchiban();
    this.load();
    // });
  },
  methods: {
    async addCategory() {
      this.selectedItem = null;
      this.dialog.open = true;
      this.dialog.type = "create";
    },
    async load() {
      const { data } = await this.axios.get(`/ichibanCategory`);
      this.data = data;
    },
    async getIchiban() {
      const { data } = await this.axios.get(`/ichiban`, {
        params: { isOnline: true },
      });
      this.ichibans = data;
    },
    async update(item, key) {
      await this.axios.put(`/ichibanCategory/${item._id}`, {
        [key]: item[key],
      });
    },
    async openDialog(item, type) {
      this.selectedItem = item;
      this.dialog.open = true;
      this.dialog.type = type;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.empty-label {
  position: relative;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
}
.typeWidth {
  width: 140px;
}
</style>
